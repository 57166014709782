.divContenedor {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.contHijo {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2B2B2B;
    z-index: 3;
}

.divContenedorHijo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
    text-align: center;
}

.conTxt {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 40%;
}

.txtLoad {
    color: #fff;

}

.conTxt span {
    animation: salto 1s alternate infinite;
}

.conTxt span:nth-child(1) {
    animation-delay: 0.20s;
}


.contenedor-loader {
    width: 100%;
    display: flex;
    justify-content: center !important;
    position: absolute;
    margin: 0 auto;
}

.contenedor-loader div {
    width: 100%;
    animation: salto 1s alternate infinite;

}

.contenedor-loader div:nth-child(1) {
    animation-delay: 0.20s;
}


.loader1 {
    width: 80px;
}


@keyframes salto {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}