html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
  background-color: #2b2b2b;
  font-family: "Montserrat" !important;
  overflow-x: hidden !important;
}

a:link,
a:visited,
a:active {
  position: relative;
  text-decoration: none !important;
  color: rgb(0, 0, 0);
  font-size: 14px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

:-webkit-scrollbar {
  display: none;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:thin,extra-light,light,100,200,300,400,500,600,700,800");

.linkCompro {
  color: #fff !important;
  font-size: 12px !important;
  font-family: "Montserrat" !important;
  text-decoration: none !important;
  cursor: pointer;
}

.contGeneral {
  width: 100%;
}

.contLogo {
  padding-right: 0%;
}

.contDrp {
  padding-right: 0% !important;
  padding-left: 0%;
}

.contHeader {
  margin-right: 0%;
}

.lineaNaraja {
  width: 103.2%;
}

.contWeb {
  position: absolute;
  width: 100% !important;
  margin: 0%;
  padding: 0%;
  z-index: 1;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown {
  display: flex;
  justify-content: right;
  right: 3%;
  top: 33%;
  box-shadow: none;
}

.conVolLog {
  bottom: 18px;
  position: absolute;
  left: 0px;
  color: #fff !important;
}

.flechaVolLog {
  padding-right: 5px;
  padding-top: 6%;
  font-size: 13px;
}

.txtVolLog {
  color: #fff !important;
}
.aIndec{
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  color: #fff !important;
}
.bolsaRos {
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  color: #5c5c5c !important;
}

.contTxtBolsa {
  display: flex;
  justify-content: left;
  color: #5c5c5c;
  padding-left: 9%;
}
.contTxtIndice{
  display: flex;
  justify-content: left;
  padding-left: 26%;
  color: #fff;
}
.contTxtEst {
  display: flex;
  justify-content: left;
  color: #5c5c5c;
  padding-left: 9%;
}

.contDist {
  display: flex;
  justify-content: left;
}

.contVer {
  display: flex;
  justify-content: right;
  color: #5c5c5c;
}

.txtBolsa {
  font-size: 12px;
}

.txtver {
  font-size: 12px;
}

.colorIcono {
  color: #e85217 !important;
  font-size: 15px;
  padding-right: 10px !important;
}

.iconoCerrar {
  font-size: 13px;
  padding-right: 6px;
}

.iconoDes {
  font-size: 13px;
  padding-right: 6px;
}

.iconoNot {
  font-size: 13px;
  padding-right: 7px;
}

.email {
  font-size: 13px;
}

.menuDropdown {
  width: 200px;
}

.contMain {
  background-image: url(../assets/Dark_background_1920x1080.png);
  background-position: "center";
  background-size: "cover";
  background-repeat: "no-repeat";
  background-attachment: "fixed";
  width: "80%";
  height: "84.5vh";
}

.txtDropdown {
  font-size: 15px;
  font-family: "Montserrat" !important;
  font-weight: 400;
}

.cotizPadd {
  padding-bottom: 10px;
}

.numVersion {
  padding-right: 10px;
}

.iconoEmail {
  width: 15px;
  padding-right: 3%;
}

.iconoUser {
  font-size: 13px;
  padding-right: 9px;
}

.iconoMov {
  position: relative;
  font-size: 13px;
  padding-right: 9px;
  cursor: pointer;
}

.iconoTrigo {
  color: #eccb0f;
  font-size: 14px;
  margin-top: 6px;
  padding-right: 4px;
}

.iconoSoja {
  color: #afcc22;
  font-size: 14.5px;
  margin-top: 5px;
  padding-right: 4px;
}

.iconoIccMca {
  width: 20.0167px;
  height: 20.0167px;
  margin-right: 3px;
  position: relative;
  bottom: 1px;
}

.iconoMaiz {
  width: 14.0167px;
  height: 14.0167px;
  margin-right: 3px;
  position: relative;
  bottom: -5px;
}

.iconoDolarBlue {
  color: #3d6fb8;
  font-size: 14px;
  margin-top: 5px;
  padding-right: 4px;
}

.iconoDolar {
  color: #81b562;
  font-size: 14px;
  margin-top: 5px;
  padding-right: 4px;
}

.txtNom {
  font-size: 13px;
  font-weight: 500;
}

.txtInstruc {
  padding-left: 1px;
  font-size: 12.5px;
  font-weight: 500;
  cursor: pointer;
}
.txtExpZucom {
  font-size: 12.5px;
  font-weight: 500;
  cursor: pointer;
}

.txtMov {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.negativo {
  color: #c3cf17;
}

.contMov {
  display: flex;
  justify-content: center !important;
}

.colorDatos {
  color: #fff;
}

.contenedorVolverEstadisticas {
  padding-top: 2%;
  padding-bottom: 3%;
}

.contenedorBotMov {
  color: #fff;
  display: flex;
  justify-content: center;
  height: 60;
}

.contTitleRc {
  display: flex;
  justify-content: left;
}

.spanTituloRc {
  font-size: 18px !important;
  color: #fff;
  margin-bottom: 10px;
}

.clase-inicial {
  color: #fff;
}

.contenedorCoins {
  color: #fff;
  display: flex;
  justify-content: center;
  height: 60px;
}
.contenedorVolerInicio {
  color: #fff;
  display: flex;
  justify-content: center;
  height: 40px;
}

.contenedorDolarBlu {
  padding-left: 0% !important;
  padding-right: 5%;
  display: flex;
  justify-content: left;
}

.contenedorDolar {
  display: flex;
  justify-content: right;
}

.contCotiz {
  display: flex;
  justify-content: center !important;
  padding-top: 3%;
}

.contTxtZ {
  padding-right: 0% !important;
  display: flex;
  justify-content: left;
}

.header-inicio {
  width: 100% !important;
  height: 100px;
  background: #fff;
}

.posBoton {
  display: flex;
  justify-content: left;
}

.iconoOjo {
  cursor: pointer;
  margin-left: 20px;
  margin-bottom: 8px;
}
.imgAndroid {
  width: 150px;
}

.contNomUser {
  cursor: default !important;
}

.contCard {
  display: flex;
  justify-content: center;
}

.iconoDiam {
  font-size: 18px;
  color: #e85217;
  margin-top: 4px;
  padding-bottom: 3px;
  padding-right: 5px;
}

.contTituloGrillaAnual {
  display: flex;
  justify-content: space-around;
  align-items: center; /* Para centrar verticalmente los elementos */
}
.contTituloGrillaRendimiento{
  display: flex;
  justify-content: space-evenly;
  align-items: center; /* Para centrar verticalmente los elementos */
}


.imgDiamante {
  font-size: 18px;
  color: #e85217;
  padding-bottom: 1px;
}

.iconDiamante {
  font-size: 18px;
  color: #e85217;
  padding-bottom: 6.5px;
}

.imgDiamanteMov {
  width: 15px;
  height: 20px;
  margin-top: 2px;
  margin-right: 5px;
}

.containerBtnMov {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e85217;
  height: 28px;
  width: 35%;
}

.containerMov {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0%;
}

.container-img {
  padding-top: 2%;
  padding-bottom: 1%;
  padding-left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenidoInicio {
  width: 100%;
}

.contenedorImgLogin {
  display: flex;
  justify-content: center;
}

.img-logoLogin {
  position: relative;
  width: 250px;
  top: 10%;
  margin-bottom: 60px;
}

.img-logoRc {
  position: relative;
  width: 250px;
  top: 10%;
  margin-bottom: 10px;
}

.img-logo {
  width: 230px;
  left: 50%;
}

.iniSesion {
  position: relative;
  left: 7%;
  color: #fff;
  font-size: 22px;
  font-weight: 500;
}

.tituloGrillaRemdimiento{
  margin: 0;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: bold;
  fill: rgb(255, 255, 255);
}

.tituloGrillaAnual {
  margin: 0;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-family: "Montserrat";
  font-weight: bold;
  fill: rgb(255, 255, 255);
}

/* botones */
.contenedorBotonesNav {
  padding-top: 30px;
  padding-bottom: 20px;
}

.contenedorInicio {
  display: flex;
  justify-content: right;
  height: 30px;
}

.contenedorGrafico {
  display: flex;
  justify-content: left;
  height: 30px;
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.btnsNav {
  color: #fff;
  border-radius: 0;
  background: #e85217;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 40%;
  border-color: #e85217;
  font-family: "Montserrat" !important;
  height: 89%;
}

.btnsNav:hover {
  background-color: #9e3309;
  border-color: #9e3309;
}

/* fin botones */

/*  estilos inicio  */

.divTitulo {
  background-color: rgba(51, 51, 51, 0.7);
  height: 230px;
  margin: 0 auto;
  color: #fff;
  margin-top: 2.5%;
}

.contCarga {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.463);
  border-left-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1400ms linear infinite;
  border-left-color: #e85217;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.divBienvenida {
  display: flex;
  justify-content: center;
  height: 40px;
  margin-top: 3%;
}

.divIcc {
  padding-left: 5% !important;
}

.nomClienteGrilla {
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  fill: rgb(255, 255, 255);
  padding-top: 25px;
}

.contZucom {
  border-bottom: 2px solid #5c5c5c;
  padding-bottom: 5px;
}

.invActual {
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  padding-bottom: 1%;
  margin-bottom: 0% !important;
}

.contInv {
  text-align: center;
}

.txtCotiz {
  padding-top: 3%;
  padding-bottom: 4%;
  text-align: center;
  color: #fff;
  font-size: 20px !important;
  font-weight: 600;
}

.contBienvenida {
  padding-top: 4%;
  text-align: center;
  color: #fff;
}

.nomCliente {
  padding-top: 4%;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-size: 24.3px;
  font-weight: 600;
}

.decimalTotal {
  float: right;
  position: absolute;
  font-size: 14px;
  cursor: pointer;
  height: 20px;
}

.titulomovimiento {
  padding-top: 4%;
  font-size: 24.3px;
  font-weight: 600;
}

.tituloMov {
  padding-top: 4%;
  margin-bottom: 2.5%;
  text-align: center;
  color: #fff;
}

.cantCoins {
  text-align: center;
  font-size: 21px;
  font-weight: 600;
  padding-left: 10px;
}

.tituloCoin {
  display: block;
  font-size: 20px;
  font-weight: 600;
  padding-left: 6px;
}
.contBotonRelog {
  padding-top: 5%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.contTotalZ {
  background-color: #e85217;
  text-align: center;
}

.signoPeso {
  position: relative;
  bottom: 6px;
  padding-right: 5px;
  font-weight: 600;
  font-size: 24px;
  cursor: pointer;
}

.valCoin {
  position: relative;
  line-height: 1.2;
  font-size: 45px !important;
  font-weight: 600;
  margin-bottom: 0%;
  cursor: pointer;
}

.tituloGrafico {
  color: #fff;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 20px;
}

.varMensual {
  font-size: 18px;
  font-weight: 500;
  padding-left: 10px;
}

.arrowUp {
  color: #11d574;
}

.arrowDown {
  color: #d92c01;
}
.txtMca {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
.txtParentecis {
  font-size: 13px;
}

.txtDolar {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.txtIcc {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.txtIccDos {
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  padding-top: 2%;
  padding-right: 2%;
}

.txtValDolar {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.txtValIcc {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.cantCompra {
  color: #11d574;
}

.imgDiamanteChico {
  width: 15px;
}

.contenedorCotiz {
  display: flex;
  justify-content: left !important;
}

.contenedorVarGrafico {
  margin-top: 2%;
  margin-bottom: 2.5%;
}

.contenedorVar {
  margin-top: 1%;
  margin-right: 0%;
}

.contCotizDos {
  display: flex;
  justify-content: right !important;
}

.contTotAcop {
  padding-top: 1%;
  padding-bottom: 2%;
}

.contenedorIcc {
  display: flex;
  justify-content: right;
}

/* Tabla */
.contenedorTabla {
  margin-top: 2.5%;
  margin-left: 0.1px;
  margin-right: 2px;
}
.contenedorTablaEst {
  margin-top: 1%;
  margin-left: 0.1px;
  margin-right: 2px;
}

.contenedorTablaAnual {
  margin-top: 0.5%;
  padding-left: 10px;
}

.contTabla {
  padding-left: 6px;
}

.contTxtPag {
  display: flex;
  justify-content: center;
}

.contBtnAnt {
  display: flex;
  justify-content: right;
  height: 25px;
  padding-right: 3px !important;
}

.contBtnSig {
  display: flex;
  justify-content: left;
  height: 25px;
  padding-left: 3px !important;
}

.btn,
.btnChico {
  --bs-btn-padding-y: 0rem;
  --bs-btn-padding-x: 0.15rem;
  --bs-btn-font-size: 12px !important;
  --bs-btn-border-radius: 0.08rem;
}

.txtVer {
  font-size: 13px;
}

.txtPaginacion {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  color: #fff;
  font-size: 12px;
  width: 200px;
}

.btnSig {
  font-size: 12px !important;
  color: #fff;
  background: #807977;
  opacity: 0.9;
  width: 15%;
  height: 20px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  border-color: #807977;
  font-family: "Montserrat" !important;
}

.colorIconoBtn {
  color: #fff;
}

.btnSig:hover {
  /* background-color: #d35321; */
  background-color: #9e3309;
  border-color: #9e3309;
}

.fechaTabla {
  font-size: 12px;
  font-weight: 300;
}

.varTabla {
  font-size: 10px;
  font-weight: 300;
}

.contenDes {
  width: 180px !important;
}

.text-left {
  text-align: left !important;
}

table {
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px;
}

table th {
  position: sticky;
  top: 0px;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  outline: 1px solid #2b2b2b;
  border: 3px solid #2b2b2b;
}

table td {
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  outline: 1px solid #2b2b2b;
  border: 3px solid #2b2b2b;
}

.selectRendimiento{
  padding: 8px; 
  font-size: 16px; 
  font-weight: 700;
  border: 1.5px solid #fff !important; 
  border-radius: 4px; 
  color: #fff;
  background-color: transparent;
}
.selectRendimiento:focus {
  background-color: #2b2b2b;
  font-family: "Montserrat" !important;
}

/* Cambiar el color del borde cuando se enfoca */
.selectRendimiento:focus {
  border-color: #999;
  font-family: "Montserrat" !important;

}

/* Cambiar el estilo del cursor cuando se coloca sobre el select */
.selectRendimiento:hover {
  cursor: pointer;
}

/* Cambiar el estilo del cursor cuando se desactiva el select */
.selectRendimiento:disabled {
  opacity: 0.5; /* Reduce la opacidad para indicar que está desactivado */
}
.contHdosRendimiento{
  display: flex;
  justify-content: right;
  padding-right: 2%;
}
.contSelectRendimientoIndec{
  padding-left: 1.8%;
}
.contSelectRendimiento{
  padding-left: 4.5%;
}
/* fin tabla */
.conteRc {
  width: 100%;
  height: 100%;
}

.contBoxRc {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 100%;
  margin: 0 0 0 0;
}

.txtTimer {
  color: #fff !important;
  font-size: 11px;
}

.txtSuccess {
  color: #0f5132;
  font-size: 12px;
}
.contPaginacion {
  display: flex;
  justify-content: center;
  padding-right: 0%;
}
.container-fluid {
  padding: 0% !important;
  margin: 0% !important;
}

.contSuccess {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  height: 60px;
  background-color: #d1e7dd;
}

.contDanger {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  height: 40px;
  background-color: #f8d7da;
}

.txtDanger {
  color: #842029 !important;
  font-size: 12px;
}

.boxRcDos {
  position: relative;
  background-color: rgba(51, 51, 51, 0.9);
  max-width: 450px;
  height: 535px;
  margin-top: 5%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.boxRc {
  position: relative;
  background-color: rgba(51, 51, 51, 0.9);
  max-width: 450px;
  height: 385px;
  margin-top: 5%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.btnRc:hover {
  /* background-color: #d35321; */
  background-color: #9e3309;
  border-color: #9e3309;
}

.btnRcDos:hover {
  /* background-color: #d35321; */
  background-color: #9e3309;
  border-color: #9e3309;
}

.btnRc {
  font-size: 13px;
  color: #fff;
  border-radius: 0;
  background-color: #e85217 !important;
  background: #e85217;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 80%;
  border-color: #e85217;
  font-family: "Montserrat" !important;
  height: 80%;
}

.btnRcDos {
  font-size: 13px;
  color: #fff;
  border-radius: 0;
  background: #e85217;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  width: 100%;
  border-color: #e85217;
  font-family: "Montserrat" !important;
  height: 90%;
}

.btnRecCont {
  display: flex;
  justify-content: left;
  color: #fff !important;
  font-size: 13px !important;
  padding-bottom: 2.5%;
}

.boxLogin {
  position: relative;
  background-color: rgba(51, 51, 51, 0.9);
  max-width: 450px;
  height: 480px;
  margin-top: 5%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.botonRegisAlerta {
  position: relative;
  color: #fff !important;
  display: flex;
  justify-content: initial;
}

.botonRegis {
  position: relative;
  color: #fff !important;
  display: flex;
  justify-content: center;
  top: 25%;
}

.contBotRc {
  height: 45px;
  bottom: 0px;
  right: 0px;
  position: absolute;
}

.contBotRelog {
  position: relative;
  top: 10%;
  display: flex;
  justify-content: center;
  height: 45px;
}
.txtRelog {
  font-size: 13px;
  font-weight: 600;
  font-family: "Montserrat" !important;
}

.contBotInicio {
  position: relative;
  top: 10%;
  display: flex;
  justify-content: right;
  height: 45px;
}
.colorBlan {
  color: #fff !important;
}
.contenedorBtnsIni {
  position: relative;
  top: 25%;
}

.carousel {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 250px; /* Alto deseado del carrusel */
  overflow: hidden;
  position: relative;
  margin-bottom: 15px;
  left: 2%;
}

.carousel img {
  height: 100%;
  width: 90%;
}

.carousel img.hidden {
  opacity: 0;
  position: absolute;
  transition: opacity 0.5s;
}

.carousel img.active {
  opacity: 1;
}

.txtCarDist {
  text-align: center;
  color: #fff;
  font-size: 13px !important;
  font-weight: 600;
  padding-left: 10%;
}

/* style Registro */

.boxRegistro {
  background-color: #333333;
  max-width: 450px;
  max-height: 1200px;
  margin-top: 5%;
  margin-bottom: 3%;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.img-logoRegistro {
  position: relative;
  width: 250px;
  top: 10%;
  margin: 16px;
}

.selectProvincias {
  background-color: #333333 !important;
  font-family: "Montserrat" !important;
}

select,
option {
  font-size: 15px !important;
  font-family: "Montserrat" !important;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 30px;
}

.lds-ellipsis div {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

/* fin style registro */

.contenedorExp {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 805px;
  background-color: rgba(32, 32, 32, 0.7);
  z-index: 3;
  color: #fff;
  padding: 0%;
  margin-top: 0% !important;
  --bs-gutter-x: 0.5rem;
}
.contExp {
  width: 100%;
  height: 100%;
}
.contSalirExp {
  height: 50px;
  padding-top: 15px;
  padding-right: 20px;
  display: flex;
  justify-content: right;
  font-size: 23px;
}

.contImgExp {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 0% !important;
  padding-right: 0% !important;
  top: -50px;
}
.contBtnOmitir {
  position: relative;
  top: -120px;
}

.imgZucomExp {
  width: 280px;
}
.carousel-control-prev-icon {
  font-size: 10px !important;
}

.item {
  display: flex;
  justify-content: center;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  background-color: transparent !important;
  color: #fff;
  font-size: 1.2em;
  padding: 0.5em 1em;
  border: none !important;
  border-radius: 0.3em;
  text-decoration: none;
}

.buttonPrev {
  color: #fff;
  background-color: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 256px;
}
.buttonNext {
  color: #fff;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: -300px;
}

.buttonPrevCel {
  color: #fff;
  background-color: transparent;
  border: none;
  position: absolute;
  left: 0;
  top: 177px;
}
.buttonNextCel {
  color: #fff;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: -290px;
}
.buttonNextCelAnd {
  color: #fff;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: -230px;
}

.alice-carousel__next-btn {
  position: absolute;
  right: 0;
}

.buttonOmitirExp {
  width: 100px;
  height: 33px;
  color: #fff;
  background-color: #e85217;
  border: none;
}
.contTxtCarrucel {
  color: #fff;
  padding-top: 22%;
  font-size: 18px;
  font-weight: 800;
  padding-left: 95px;
  word-wrap: break-word;
}
.contTxtCarrucelTres {
  color: #fff;
  display: flex;
  justify-content: center;
  padding-top: 50%;
  font-size: 18px;
  font-weight: 800;
  word-wrap: break-word;
}
.contPrimerTxx {
  padding-bottom: 25px;
}
.txtExplicac {
  font-weight: 600;
}
.txtExtrabol {
  font-weight: 800;
}
.txtBold {
  font-weight: 600;
}

.txtNaranja {
  color: #e85217;
}
.contTextosDesc {
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding-bottom: 0% !important;
  margin-bottom: 0% !important;
  padding-right: 0% !important;
  margin-right: 0% !important;
}
.txtDescar {
  font-size: 23px;
  font-weight: 800;
}
.txtZhInver {
  color: #e85217;
}
.contTxtCels {
  padding-right: 0% !important;
  margin-right: 0% !important;
}
.contEmpc {
  padding-right: 0% !important;
  padding-top: 20px;
}
.txtEmpc {
  font-size: 18px;
  font-weight: 600;
}
.contPrimerTex {
  padding-top: 15% !important;
  padding-bottom: 0% !important;
  margin-bottom: 0% !important;
  padding-right: 0% !important;
}
.celusUno {
  display: flex;
  justify-content: center;
  width: 200px;
  padding-top: 20px;
  cursor: pointer;
}
.contenedorImgCelularUno {
  display: flex;
  justify-content: right;
}
.imgInstruc {
  padding-top: 20px;
  width: 200px;
}
.imgInstrucCua {
  padding-top: 20px;
  width: 220px;
}
.contImgImgIntruc {
  display: flex;
  justify-content: center;
}
.contBtnVolEle {
  display: flex;
  justify-content: center;
}
.contBtnDispAnd {
  position: relative;
  bottom: -500px;
}
.contBtnDisp {
  position: relative;
  display: flex;
  justify-content: right;
  padding-right: 35px;
  top: 30px;
}
.contBtnDispDo {
  position: relative;
  display: flex;
  justify-content: left;
  padding-left: 35px;
  top: 30px;
}
.contGeneralIntruc {
  margin-right: 0% !important;
}
.contSegCel {
  padding-right: 0% !important;
  margin-right: 0% !important;
}
.botonVolDisp {
  color: #fff;
  height: 35px;
  background-color: #e85217;
  border-color: #f85717;
  font-weight: 500;
}

.alice-carousel__dots-item:not(.__custom) {
  background-color: #e85217 !important;
}
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #9e3309 !important;
}
.botonVolEle {
  color: #fff;
  height: 35px;
  background-color: #e85217;
  border-color: #f85717;
  font-weight: 500;
}
.custom_htmlContainer {
  text-align: left !important;
}
.custom_title {
  font-size: 20px !important;
}

.confirmButton {
  display: flex !important;
  justify-content: center;
  width: 45px;
  height: 30px;
  align-items: center;
}

@media (min-width: 322px) {
  .img-logo {
    position: relative;
    align-items: center;
  }

  .contenedorCotiz {
    justify-content: start;
  }
}

@media (min-width: 401px) {
  .card-posicion {
    width: 99% !important;
  }
}

@media (max-width: 400px) {
  .buttonNextCel {
    top: -306px !important;
  }
  .card-posicion {
    width: 94% !important;
  }

  .txtDropdown {
    font-size: 12px !important;
  }

  .colorIcono {
    font-size: 11px;
  }

  .img-logo {
    width: 160px !important;
  }
}

@media (max-width: 430px) {

  .tituloGrillaAnual{
    font-size: 16px !important;
  }
  .continver {
    width: 400px !important;
  }

  .btnRecCont {
    font-size: 11px !important;
  }

  .btnRcDos {
    font-size: 13px;
    height: 95%;
  }

  .btnRc {
    font-size: 13px;
    width: 100%;
    height: 80%;
  }

  .txtRelog {
    font-size: 12px;
  }

  .btnLogin {
    font-size: 13px;
    width: 100%;
    height: 80%;
  }

  .botonRegisAlerta {
    font-size: 12px !important;
  }

  .botonRegis {
    font-size: 12px !important;
  }

  .divBienvenida {
    margin-top: 7% !important;
  }
}

@media (max-width: 900px) {
  .imgInstruc {
    padding-top: 20px;
    width: 170px;
  }
  .botonVolDisp {
    font-size: 13px;
  }
  .celusUno {
    width: 180px;
  }
  .img-logo {
    left: 0%;
  }

  .containerBtnMov {
    width: 45%;
  }
}

@media (max-width: 1350px) {
  .btnGraf {
    font-size: 16px !important;
    width: 40% !important;
    height: 80% !important;
  }
}

@media (max-width: 700px) {
  .contSelectRendimientoIndec{
    padding-left: 4.5%;
  }
  .tituloGrillaAnual{
    font-size: 15px !important;
  }
  .tituloGrillaRemdimiento{
    font-size: 15px !important;
  }
  .contenedorTablaAnual{
    padding-bottom: 2% !important;
  }
  .contTxtIndice{
    padding-bottom: 40px;
    padding-left: 2%;
  }
  .lineaNaraja {
    width: 102.8%;
  }

  .carousel {
    left: 1.5%;
    height: 180px;
  }
  .carousel img {
    width: 90%;
  }
  .txtCarDist {
    font-size: 11px !important;
  }
  .celusUno {
    width: 150px;
  }
  .img-logo {
    left: 0%;
  }

  .containerBtnMov {
    width: 45%;
  }
}

@media (max-width: 584px) {
  .btnGraf {
    font-size: 16px !important;
    width: 70% !important;
    height: 80% !important;
  }

  .contTxtPag {
    padding-left: 19%;
  }
  .contBtnDisp {
    justify-content: right;
    padding-right: 20px;
    top: 30px;
  }
  .contBtnDispDo {
    justify-content: left;
    padding-left: 25px;
  }
  .contCelulares {
    position: relative;
    top: -30px !important;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
    padding-right: 0% !important;
    margin-right: 0% !important;
  }
  .contImgUno {
    display: flex;
    justify-content: center;
  }
  .contTextosDesc {
    margin-right: 0% !important;
    padding-right: 0% !important;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }
  .contPrimerTex {
    padding-right: 0% !important;
    height: 30px;
    padding-bottom: 0% !important;
    margin-bottom: 0% !important;
  }
  .celusUno {
    display: flex;
    justify-content: center;
    width: 150px;
    padding-top: 0;
    top: -300px !important;
  }

  .buttonPrevCel {
    padding-left: 15px !important;
    top: 290px;
  }
  .buttonNextCel {
    top: -293px;
  }
  .buttonNextCelAnd {
    top: -307px;
  }
  .contTxtCarrucel {
    padding-top: 50px !important;
    padding-left: 30px !important;
  }
  .contTxtCarrucelTres {
    padding-top: 50px !important;
    padding-bottom: 20px;
    padding-left: 30px !important;
  }
  .buttonPrev {
    top: 204px;
  }
  .buttonNext {
    top: -300px;
  }

  .contImgExp {
    top: -20px;
  }

  .contBtnOmitir {
    top: -30px;
  }
  .imgZucomExp {
    width: 250px;
  }

  .contenedorExp {
    height: 100%;
  }
  .txtNom {
    font-size: 11px;
  }
  .txtInstruc {
    font-size: 11px;
  }
  .txtExpZucom {
    font-size: 11px;
  }
  .txtMov {
    font-size: 11px;
  }

  .boxDrop {
    display: flex;
    justify-content: right;
  }

  .aIndec{
    font-size: 10px !important;
  }

  .bolsaRos {
    font-size: 10px !important;
  }
  .txtBolsa {
    font-size: 10px;
  }

  .txtVer {
    font-size: 10px;
  }

  .containerBtnMov {
    width: 60%;
  }

  .contenedorBotMov {
    padding-top: 3%;
  }

  .iconoTrigo {
    margin-top: 3px;
  }

  .iconoSoja {
    margin-top: 3px;
  }
  .iconoIccMca {
    width: 18px;
    height: 18px;
    bottom: 2px;
  }

  .iconoMaiz {
    bottom: -1px;
  }

  .iconoDolarBlue {
    margin-top: 3px;
  }

  .iconoDolar {
    margin-top: 3px;
  }

  .contTxtZ {
    justify-content: left;
  }

  .boxRegistro {
    height: 1020px !important;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  }

  .txtSuccess {
    height: 65px;
  }

  .txtDanger {
    height: 55px;
  }

  .boxRcDos {
    height: 475px;
  }

  .boxLogin {
    height: 470px;
    margin-top: 25%;
  }

  .btnsNav {
    width: 55%;
  }

  .txtCotiz {
    font-size: 13px !important;
  }

  .iconoDiam {
    font-size: 14px;
    margin-top: 2.4px;
  }

  .divTitulo {
    height: 225px !important;
    margin-bottom: 4% !important;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }

  .invActual {
    font-size: 18px;
    padding-bottom: 3%;
  }

  .contTotAcop {
    padding-bottom: 3%;
    padding-right: 0%;
  }

  .container-img {
    padding-top: 3%;
    margin-bottom: 3% !important;
  }

  .contenedorTablaAnual {
    padding-bottom: 10%;
    padding-right: 10px;
  }

  .dropdown {
    right: 5%;
    padding-top: 10px;
  }
  .contDetall {
    padding-right: 0% !important;
  }

  .iconInfoDes {
    font-size: 13.5px !important;
  }

  .descAcop {
    font-size: 12px;
  }

  .contPrecio {
    width: 100px !important;
  }

  .contenedorDolar {
    display: flex;
    justify-content: center;
  }

  .card-posicion {
    width: 94% !important;
  }

  /* Tabla */
  .contBtnAnt {
    height: 20.2px;
  }

  .contBtnSig {
    height: 20.2px;
  }

  .contMonto {
    font-size: 12px;
  }

  .contCant {
    font-size: 12px;
  }

  .contenDes {
    width: 129.9px !important;
    height: 35px !important;
    font-size: 12px;
  }

  .btn,
  .btnChico {
    --bs-btn-padding-y: 0.1rem;
    --bs-btn-padding-x: 0.25rem;
    --bs-btn-font-size: 10px !important;
    --bs-btn-border-radius: 0.1rem;
  }

  .btnSig {
    width: 30%;
    font-size: 10px !important;
  }

  .txtPaginacion {
    font-size: 10.5px;
  }

  .fcZkth {
    font-size: 12px !important;
  }

  .jaZsat {
    font-size: 12px !important;
  }

  .kCrepa {
    min-height: 40px !important;
  }

  .rdt_TableHeadRow {
    min-height: 40px !important;
  }

  .rdt_TableCol {
    font-weight: 600;
    font-size: 10px;
  }

  /* Fin tabla */

  .img-logo {
    padding-top: 10px;
    width: 180px;
  }

  .divIcc {
    padding-left: 0% !important;
  }

  .contenedorCotiz {
    justify-content: center !important;
  }

  .contCotizDos {
    justify-content: center !important;
  }

  .divBienvenida {
    margin-top: 4%;
  }

  .contenedorCoins {
    height: 40px;
  }

  .contenedorVolerInicio {
    height: 40px;
  }

  .imgDiamante {
    font-size: 16px;
  }

  .iconDiamante {
    font-size: 16px;
  }

  .imgDiamanteMov {
    height: 16px;
    width: 12px;
    margin-right: 5px;
  }

  .titulomovimiento {
    font-size: 18px;
  }

  .nomCliente {
    font-size: 18px;
  }

  .cantCoins {
    font-size: 18px;
  }

  .tituloCoin {
    font-size: 18px;
  }

  .valCoin {
    font-size: 25px;
  }

  .varMensual {
    font-size: 17px;
  }
  .txtMca {
    font-size: 13px;
  }
  .txtParentecis {
    font-size: 10px;
  }

  .txtDolar {
    font-size: 13px;
  }

  .txtIcc {
    font-size: 13px;
  }

  .txtIccDos {
    font-size: 10px;
  }

  .txtValDolar {
    font-size: 14px;
  }

  .txtValIcc {
    font-size: 14px;
  }
}
